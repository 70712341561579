import { EmailParams } from "mailersend"
import emailClient from "lib/clients/mailersend"

import MARKETING_EMAIL_DOMAIN_BLOCKLIST from "./emails/blocklist"

export const validateEmail = (email: string): boolean => {
  const emailRegEx = /^\S+@\S+\.\S+$/

  return emailRegEx.test(email)
}

export const validateEmailDomainNotBlocklisted = (domain: string): boolean => {
  return !MARKETING_EMAIL_DOMAIN_BLOCKLIST.includes(domain)
}

export const sendWithRetry = async (emailParams: EmailParams) => {
  let attempts = 0
  const maxAttempts = 3

  // Since the emailParams object is passed by reference and this function doesn't
  // block the parent thread, we need to create a deep copy
  const emailParamsDeepCopy = JSON.parse(JSON.stringify(emailParams))

  while (attempts <= maxAttempts) {
    attempts++

    await new Promise((resolve) => setTimeout(resolve, 1000 * attempts))

    try {
      await emailClient.email.send(emailParamsDeepCopy)
      return
    } catch {
      if (attempts === maxAttempts) {
        throw new Error(ErrorMessage.EmailSendError)
      }
    }
  }
}

export const sendBulkWithRetry = async (emailParams: EmailParams[]) => {
  for (const email of emailParams) {
    await sendWithRetry(email)
  }
}

export enum MailersendTemplate {
  ResetPassword = "3z0vklo6kyxl7qrx",
  InvitedToOrgEmail = "v69oxl5en82l785k",
  GenericEmail = "vywj2lpn2zkg7oqz",
  WelcomeEmail = "jy7zpl9ny5r45vx6",
  TeamLeadDigest = "k68zxl2z7y5lj905",
  TeamLeadDigestV2 = "zr6ke4n7mjmgon12",
  TaskUpdate = "0r83ql3xrdmlzw1j",
}

export enum ErrorMessage {
  InvalidEmail = "The email provided was not valid",
  InvalidParams = "Invalid parameters: ensure one or more of the params are not empty",
  EmailSendError = "Failed to send email",
}
